
import statusListData from "@/app/infrastructures/misc/common-library/StatusListData";
import {
  downloadCsvFromCsvContent,
  formatDate,
  parsingErrorResponse,
} from "@/app/infrastructures/misc/Utils";
import { OptionsClass } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { CourierManagementDetail } from "@/domain/entities/CourierManagement";
import { Vue } from "vue-class-component";
import { ChipsStyles } from "../../../components/chips-v2/modules";
import { AccountController } from "../../../controllers/AccountController";
import { CourierManagementController } from "../../../controllers/CourierManagementController";
import { ApiRequestCourierManagementList } from "@/data/payload/api/CourierManagementApiRequest";
import { MainAppController } from "../../../controllers/MainAppController";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import { FlagsPermissionCourierManagement } from "@/feature-flags/flags-permission-courier-management";

export default class CargoConfigurationList extends Vue {
  mounted() {
    FlagsPermissionCourierManagement.permission_courier_management_enable.isEnabled() ? this.fetchList() : '';
  }
  get title(): string {
    return (this.$route as any)?.meta?.title;
  }

  // advance filter
  get isCollapseSidebar() {
    return AccountController.isCollapseSidebar;
  }
  onResetAdvancedFilter(isFetch: boolean): void {
    this.params = new ApiRequestCourierManagementList();
    this.listData.pagination.page = 1;
    if (isFetch) this.fetchList();
  }

  params = new ApiRequestCourierManagementList();
  get countTotalFilter(): number {
    return [
      this.params.courierStatus.value,
      this.params.driverType.value,
    ].filter((d: string) => !!d).length;
  }
  onSearch(value: string) {
    this.params.q = value;
    this.listData.pagination.page = 1;
    this.fetchList();
  }
  clearSearch() {
    this.params.q = "";
    this.listData.pagination.page = 1;
    this.fetchList();
  }

  get statusData(): OptionsClass[] {
    return statusListData(["active", "inactive"]);
  }
  get driverTypeData(): OptionsClass[] {
    const pos = new OptionsClass({
      name: "POS",
      value: "POS",
    });
    const kvp = new OptionsClass({
      name: "KVP",
      value: "KVP",
    });
    const truck = new OptionsClass({
      name: "Truck",
      value: "TRUCK",
    });

    switch (this.typeUser) {
      case "pos":
        return [pos, kvp];

      case "console":
      case "sub-console":
        return [truck, kvp];

      default:
        return [pos, truck, kvp];
    }
  }
  onResetList(): void {
    this.listData.pagination.page = 1;
    this.fetchList();
  }

  listData: ResponsePayloadV2 = new ResponsePayloadV2();
  async fetchList() {
    this.listData.loading = true;
    try {
      this.listData = await CourierManagementController.getList(
        new ApiRequestCourierManagementList({
          page: this.listData.pagination.page,
          perPage: this.listData.pagination.limit,
          q: this.params.q,
          courierStatus: this.params.courierStatus,
          driverType: this.params.driverType,
        })
      );
    } catch (err) {
      this.listData.message = parsingErrorResponse(err).type;
    } finally {
      this.listData.loading = false;
    }
  }
  get isEmptyList(): boolean {
    return (
      !this.listData.data.length &&
      !this.params.q &&
      !this.params.courierStatus.value &&
      !this.params.driverType.value
    );
  }

  get columns(): any[] {
    return [
      {
        name: "No",
        styleHead: "w-1/20 text-left whitespace-no-wrap",
        render: (_: CourierManagementDetail, index: number) =>
          `<span class='text-left text-red-lp-200 flex justify-center'>${(this
            .listData.pagination.page -
            1) *
            this.listData.pagination.limit +
            index +
            1}</span>`,
      },
      {
        name: "Kurir ID",
        styleHead: "w-2/20 text-left whitespace-no-wrap",
        render: (item: CourierManagementDetail) =>
          `<span class='text-left text-red-lp-200 flex justify-center'>${item.courierId}</span>`,
      },
      {
        name: "Nama Kurir",
        styleHead: "w-3/20 text-left whitespace-no-wrap",
        render: (item: CourierManagementDetail) =>
          `<span class='text-left text-black-lp-300 flex justify-center'>${item.fullname}</span>`,
      },
      {
        name: "No Handphone",
        styleHead: "w-1/5 text-left whitespace-no-wrap",
        render: (item: CourierManagementDetail) =>
          `<span class='text-left text-black-lp-300 flex justify-center'>${item.phoneNumber}</span>`,
        key: "actionable_column",
        showButton: (item: CourierManagementDetail) => !!item.phoneNumber,
        styleCustom: "flex items-center",
        icon: "whatsapp",
        disableButtonValue: false,
        onClickValue: this.goToWhatsapp,
      },
      {
        name: "Tipe Kurir",
        styleHead: "w-2/20 text-left whitespace-no-wrap",
        render: (item: CourierManagementDetail) =>
          `<span class='text-left text-black-lp-300 flex justify-center'>${item.announcementCourierType}</span>`,
      },
      {
        name: "Plat Nomor",
        styleHead: "w-2/20 text-left whitespace-no-wrap",
        render: (item: CourierManagementDetail) =>
          `<span class='text-black-lp-300 flex justify-center'>${item.vehiclePlate}</span>`,
      },
      {
        name: `Nama ${this.partnerTitle}`,
        styleHead: "w-1/5 text-left whitespace-no-wrap",
        render: (item: CourierManagementDetail) =>
          `<span class='text-black-lp-300 flex justify-center'>${item.partner.name}</span>`,
      },
      {
        name: "COD",
        styleHead: "w-2/20 text-left text-black-lp-300 whitespace-no-wrap",
        render: (item: CourierManagementDetail) =>
          `<div class="capitalize overflow-ellipsis text-left flex flex-row gap-x-1 font-medium">
              <img class="w-6 max-w-none" src="${this.convertCodIcon(item.courierIsCod)}" />
            </div>`,
      },
      {
        name: "Status",
        styleHead: "w-2/20 text-left text-black-lp-300 whitespace-no-wrap",
        render: (item: CourierManagementDetail) =>
          ChipsStyles({ status: item.courierStatus }),
      },
    ];
  }

  get typeUser(): string {
    return AccountController.accountData.account_type_detail.type;
  }
  get partnerTitle(): string {
    switch (this.typeUser) {
      case "pos":
        return "POS";

      case "console":
      case "sub-console":
        return "Consolidator";

      default:
        return "Konsol/POS";
    }
  }

  goToWhatsapp(item: CourierManagementDetail) {
    const phoneFormat = item.phoneNumber.replace(/\+/g, "");
    window.open(`https://api.whatsapp.com/send?phone=${phoneFormat}`, "_blank");
  }

  goToCreate() {
    this.$router.push("/courier-management/add");
  }
  onClickRow(item: CourierManagementDetail) {
    this.$router.push(`/courier-management/${item.courierId}`);
  }

  get isCreateAble() {
    return FlagsPermissionCourierManagement.permission_courier_management_create.isEnabled();
  }
  get isDetailAble() {
    return FlagsPermissionCourierManagement.permission_courier_management_detail_view.isEnabled();
  }

  async downloadFile() {
    try {
      MainAppController.showLoading();
      const resp = await CourierManagementController.downloadFile(
        new ApiRequestCourierManagementList({
          q: this.params.q,
          courierStatus: this.params.courierStatus,
          driverType: this.params.driverType,
        })
      );
      downloadCsvFromCsvContent({
        fileName: `Data Kurir  ${formatDate(
          new Date().toISOString(),
          "DD-MMM-YYYY HH.mm"
        )}.csv`,
        csvContent: resp.data,
      });
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(
          error,
          "Gagal Download Data Kurir",
          this.downloadFile
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  convertCodIcon(isCod: boolean) {
    if (isCod) {
      return require(`@/app/ui/assets/svg/check-circle.svg`);
    }
    return require(`@/app/ui/assets/svg/close-circle.svg`);
  }
}
