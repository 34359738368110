
import { Options, prop, Vue } from "vue-class-component";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";

class Props {
  modelValue = prop<boolean>({
    default: false,
  });
  dataHistory = prop<ResponsePayloadV2>({
    type: ResponsePayloadV2,
    default : new ResponsePayloadV2()
  })
}
@Options({
  emits: ["closeModal"]
})
export default class ModalHistoryCourier extends Vue.with(Props) {
  showingDialog = true;
  handleCloseModal() {
    this.$emit("closeModal");
  }

  get isEmptyList(): boolean {
    return !this.dataHistory?.data.length;
  }
  get columns(): any[] {
    return [
      {
        name: "No",
        styleHead: "w-1/20 text-left whitespace-no-wrap",
        render: (_: any, index: number) =>
          `<span class='text-left text-black-lp-300  flex justify-center'>${(this.dataHistory?.pagination.page -
            1) *
            this.dataHistory?.pagination.limit +
            index +
            1}</span>`,
      },
      {
        name: "Status",
        styleHead: "w-1/5 text-left whitespace-no-wrap",
        render: (item: any) =>
          `<span class='text-center px-1.5 rounded-lg flex justify-center ${
            item.status === 1
              ? "bg-green-lp-300 text-green-lp-400"
              : "bg-red-lp-600 text-red-lp-500"
          }'>${item.status.toLowerCase() === "approved" ? "Diterima" : "Ditolak"}</span>`,
      },
      {
        name: "Tipe Permintaan",
        styleHead: "w-1/5 text-left whitespace-no-wrap",
        render: (item: any) =>
          `<span class='text-black-lp-300 flex justify-center'>${
            item.type.toLowerCase() === "device register" ? "Perangkat Baru" : "DEL Baru"
          }</span>`,
      },
      {
        name: `Nama Konsol/POS`,
        styleHead: "w-6/20 text-left whitespace-no-wrap",
        render: (item: any) =>
          `<span class='text-black-lp-300 flex justify-center'>${item.posName}</span>`,
      },
    ];
  }
}
