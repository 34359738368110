import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "flex flex-col overflow-hidden p-4 w-full bg-white" }
const _hoisted_2 = { class: "flex gap-x-6 text-20px border-b border-gray-lp-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.availableTabs, (tab) => {
        return (_openBlock(), _createBlock("button", {
          key: tab.name,
          onClick: ($event: any) => (_ctx.changeTabs(tab.name)),
          class: `pb-1 font-semibold hover:text-red-lp-100 focus:outline-none ${
          _ctx.isTabActive(tab.name)
            ? 'text-red-lp-100 border-b-2 border-red-lp-100'
            : 'text-gray-lp-600'
        }`
        }, _toDisplayString(tab.title), 11, ["onClick"]))
      }), 128))
    ]),
    _createVNode(_component_router_view)
  ]))
}