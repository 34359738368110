import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import { CourierManagementDetail } from "@/domain/entities/CourierManagement";
import { OptionsClass, QueryParamsEntities } from "@/domain/entities/MainApp";
import { UpdateStatusCourierRequestInterface } from "../contracts/CourierManagementRequest";

export class ApiRequestCourierManagementList {
  page = 1;
  perPage = 20;
  limit = 0;
  q = "";
  courierStatus = new OptionsClass();
  driverType = new OptionsClass();
  constructor(fields?: Partial<ApiRequestCourierManagementList>) {
    Object.assign(this, fields);
  }
  toQueryString(): string {
    return new QueryParamsEntities(
      ConvertObjectCamelToSnakeCase({
        ...this,
        courierStatus: this.courierStatus.value,
        driverType: this.driverType.value,
      })
    ).queryString;
  }
}

export class ApiRequestCourierManagementListHistory {
  page = 1;
  perPage = 5;
  courierId = 0
  constructor(fields?: Partial<ApiRequestCourierManagementListHistory>) {
    Object.assign(this, fields);
  }
  toQueryString(): string {
    return new QueryParamsEntities(
      ConvertObjectCamelToSnakeCase({
        page: this.page,
        limit: this.perPage
      })
    ).queryString;
  }
}

export class ApiRequestCourierManagementCreate {
  payload: CourierManagementDetail = new CourierManagementDetail();

  constructor(fields?: Partial<ApiRequestCourierManagementCreate>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify(
      ConvertObjectCamelToSnakeCase({
        ...this.payload,
        ...this.payload.bank,
        partnerExternalCode: this.payload.partner.partnerExternalCode,
      })
    );
  }
}

export class UpdateStatusCourierApiRequest
  implements UpdateStatusCourierRequestInterface {
  // payload: RequestUpdate = new RequestUpdate();
  id = 0;
  type = "";
  action = "";
  constructor(fields?: Partial<UpdateStatusCourierApiRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify({
      id: this.id,
      type: this.type,
      action: this.action,
    });
  }
}

export class ApiRequestCourierVehicleList {
  search = "";

  constructor(fields?: Partial<ApiRequestCourierVehicleList>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(
      ConvertObjectCamelToSnakeCase({
        search: this.search
      })
    ).queryString;
  };
}
