import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative font-montserrat" }
const _hoisted_2 = { class: "overflow-auto h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_ModalHistoryCourier = _resolveComponent("ModalHistoryCourier")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_TableV2, {
          loading: _ctx.listData.loading,
          borderTop: false,
          borderBottom: false,
          columns: _ctx.columns,
          data: _ctx.listData.data,
          isEmpty: _ctx.isEmptyList,
          emptyHeaderMessage: "Belum Ada Data Kurir",
          emptyMessage: "Silahkan tambah kurir terlebih dahulu",
          class: "my-2",
          isDetailAble: _ctx.isDetailAble,
          pagination: _ctx.listData.pagination,
          "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.listData.pagination = $event)),
          paginationStyle: "v1",
          iconColor: "green-lp-100",
          groupAction: true,
          groupOptions: _ctx.actionGroupOptions,
          onRequest: _ctx.fetchList,
          onTryAgain: _ctx.fetchList
        }, null, 8, ["loading", "columns", "data", "isEmpty", "isDetailAble", "pagination", "groupOptions", "onRequest", "onTryAgain"])
      ])
    ]),
    (_ctx.isOpenModalApproval)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 0,
          onClose: () => _ctx.onClose('approval'),
          onSubmit: _ctx.onSubmitApprove,
          textSuccess: "Ya, diterima",
          textCancel: "Kembali",
          title: "Terima permintaan ini?",
          message: "Kurir tetap akan bisa mengakses aplikasi Lion Parcel Driver.",
          image: "badge-confirmation-general"
        }, null, 8, ["onClose", "onSubmit"]))
      : _createCommentVNode("", true),
    (_ctx.isOpenModalRejected)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 1,
          onClose: () => _ctx.onClose('rejected'),
          onSubmit: _ctx.onSubmitReject,
          textSuccess: "Ya, ditolak",
          textCancel: "Kembali",
          title: "Tolak permintaan ini?",
          message: "Kurir tidak akan bisa mengakses aplikasi Lion Parcel Driver.",
          image: "badge-confirmation-general"
        }, null, 8, ["onClose", "onSubmit"]))
      : _createCommentVNode("", true),
    (_ctx.isOpenModalSuccess)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 2,
          onSubmit: _ctx.onConfirmSuccess,
          textSuccess: "Oke, mengerti",
          title: 
      `Permintaan berhasil ${_ctx.actionType === 'REJECT' ? 'ditolak' : 'diterima'}`
    ,
          image: "badge-success"
        }, null, 8, ["onSubmit", "title"]))
      : _createCommentVNode("", true),
    (_ctx.historyCourierModal)
      ? (_openBlock(), _createBlock(_component_ModalHistoryCourier, {
          key: 3,
          modelValue: _ctx.historyCourierModal,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.historyCourierModal = $event)),
          onCloseModal: _ctx.onCloseModalHistory,
          dataHistory: _ctx.listHistoryCourierData
        }, null, 8, ["modelValue", "onCloseModal", "dataHistory"]))
      : _createCommentVNode("", true)
  ], 64))
}