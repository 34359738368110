const ChipsTitle: { [x: string]: string } = {
  active: "Aktif",
  inactive: "Tidak Aktif",
  crrnfd: "Mencari Kurir",
  crrotw: "Kurir Ditugaskan",
  crrpic: "Paket di Pick Up",
  crrdon: "Selesai",
  crrcnc: "Dibatalkan Kurir",
  cstcnc: "Dibatalkan Klien",
  admcnc: "Dibatalkan Admin"
};

const ChipsColor = (status: string) => {
  // for multiple status, use ^ before status, ex: ^active|^success
  if (status.match(/^active|^CRRDON|green/gi)) return "green";
  if (status.match(/^waiting|^CRRNFD|yellow/gi)) return "yellow";
  if (status.match(/^inactive|^CRRCNC|^CSTCNC|^ADMCNC|red/gi)) return "red";
  if (status.match(/^CRRPIC|blue/gi)) return "blue";
  if (status.match(/^CRROTW|aqua/gi)) return "aqua";
  return "gray";
};

interface Chips {
  status: string;
  title?: string;
  square?: boolean;
  boldText?: boolean;
}
export const ChipsStyles = (props: Chips) => {
  let style = "";

  switch (ChipsColor(props.status)) {
    case "green":
      style = props.boldText ? "bg-green-lp-300 font-semibold text-green-lp-200" : "bg-green-lp-300 text-green-lp-200";
      break;

    case "yellow":
      style = props.boldText ? "bg-yellow-lp-200 font-semibold text-yellow-lp-100" : "bg-yellow-lp-200 text-yellow-lp-100";
      break;

    case "red":
      style = props.boldText ? "bg-red-300 font-semibold text-red-lp-200" : "bg-red-300 text-red-lp-200";
      break;

    case "blue":
      style = props.boldText ? "bg-blue-lp-200 font-semibold text-blue-lp-300" : "bg-blue-lp-200 text-blue-lp-300";
      break;

    case "aqua":
      style = props.boldText ? "bg-aqua-lp-200 font-semibold text-aqua-lp-500" : "bg-aqua-lp-200 text-aqua-lp-500";
      break;

    case "gray":
      style = props.boldText ? "bg-gray-lp-200 font-semibold text-black-lp-300" : "bg-gray-lp-200 text-black-lp-300";
      break;

    default:
      break;
  }

  const shape = props.square ? "rounded" : "rounded-full";
  return props.status && style
    ? `<div class="flex"><div class="${shape} px-2 py-0 ${style}"><span class="capitalize">${props.title ||
        ChipsTitle[props.status.toLowerCase()] || props.status}</span></div></div>`
    : "-";
};
