
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    item: {
      default: {},
      type: Object
    },
    keyName: {
      default: "",
      type: String
    },
    index: {
      default: 0,
      type: Number
    },
    length: {
      default: 0,
      type: Number
    },
    isSelected: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onChooseSelect: {
      default: Function as PropType<() => void>,
      type: Function
    }
  }
})
export default class Select extends Vue {}
