import { OptionsClass } from "@/domain/entities/MainApp";

type Status = "" | "active" | "inactive";
const statusData = [
  { name: "Aktif", value: "active" as Status },
  { name: "Tidak Aktif", value: "inactive" as Status }
];

const statusListData = (type: Status[]): OptionsClass[] => {
  if (type.length) {
    return statusData.map(item => {
      if (type.includes(item.value)) {
        return new OptionsClass({
          value: item.value,
          name: item.name
        });
      }
      return new OptionsClass();
    });
  }
  return [new OptionsClass()];
};

export default statusListData;
