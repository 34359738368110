import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative font-montserrat" }
const _hoisted_2 = { class: "flex flex-row text-12px xxl:text-14px items-center w-full" }
const _hoisted_3 = { class: "w-1/6 whitespace-nowrap" }
const _hoisted_4 = { class: "px-2 space-y-2 pb-6" }
const _hoisted_5 = { class: "flex items-center space-x-2" }
const _hoisted_6 = { class: "overflow-auto h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_CustomDropdown = _resolveComponent("CustomDropdown")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_AdvancedFilter = _resolveComponent("AdvancedFilter")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_Header = _resolveComponent("Header")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header, null, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_Search, {
            onInput: _ctx.onSearch,
            onClear: _ctx.clearSearch,
            value: _ctx.params.q,
            class: "w-1/3",
            placeholder: "Cari nama, kurir id & no telp"
          }, null, 8, ["onInput", "onClear", "value"]),
          _createVNode("div", _hoisted_3, [
            _createVNode(_component_AdvancedFilter, {
              modelValue: _ctx.params,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.params = $event)),
              addSumFilter: _ctx.countTotalFilter,
              isDisabled: false,
              isScroll: false,
              onReset: _ctx.onResetAdvancedFilter
            }, {
              default: _withCtx(() => [
                _createVNode("div", _hoisted_4, [
                  _createVNode(_component_DataWrapper, { label: "Tipe Kurir" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_CustomDropdown, {
                        modelValue: _ctx.params.driverType,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.params.driverType = $event)),
                        options: _ctx.driverTypeData,
                        placeholder: "Pilih tipe kurir",
                        class: "w-full custom-select mt-1.5",
                        isCustomWidth: "",
                        selectedItem: _ctx.params.driverType,
                        onSelect: _ctx.onResetList
                      }, null, 8, ["modelValue", "options", "selectedItem", "onSelect"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_DataWrapper, { label: "Status" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_CustomDropdown, {
                        modelValue: _ctx.params.courierStatus,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.params.courierStatus = $event)),
                        options: _ctx.statusData,
                        placeholder: "Pilih status",
                        class: "w-full custom-select mt-1.5",
                        isCustomWidth: "",
                        selectedItem: _ctx.params.courierStatus,
                        onSelect: _ctx.onResetList
                      }, null, 8, ["modelValue", "options", "selectedItem", "onSelect"])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }, 8, ["modelValue", "addSumFilter", "onReset"])
          ])
        ]),
        _createVNode("div", _hoisted_5, [
          _createVNode(_component_LpButton, {
            class: "whitespace-nowrap",
            onClick: _ctx.downloadFile,
            textColor: "red-lp-300",
            outline: "",
            title: "Download Data Kurir",
            iconV2: "",
            icon: "file-download",
            disabled: !_ctx.listData.data.length
          }, null, 8, ["onClick", "disabled"]),
          (_ctx.isCreateAble)
            ? (_openBlock(), _createBlock(_component_LpButton, {
                key: 0,
                class: "px-6",
                title: "Tambah Kurir",
                textColor: "white",
                textSize: 10,
                customClass: "whitespace-nowrap",
                onClick: _ctx.goToCreate
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    _createVNode("div", _hoisted_6, [
      _createVNode(_component_TableV2, {
        loading: _ctx.listData.loading,
        borderTop: false,
        borderBottom: false,
        columns: _ctx.columns,
        data: _ctx.listData.data,
        isEmpty: _ctx.isEmptyList,
        emptyHeaderMessage: "Belum ada data kurir ",
        emptyMessage: "Yuk, tambah kurir dan cek daftarnya disini.",
        class: "my-2",
        onClick: _ctx.onClickRow,
        isDetailAble: _ctx.isDetailAble,
        pagination: _ctx.listData.pagination,
        "onUpdate:pagination": _cache[4] || (_cache[4] = ($event: any) => (_ctx.listData.pagination = $event)),
        onRequest: _ctx.fetchList,
        onTryAgain: _ctx.fetchList,
        paginationStyle: "v3",
        iconColor: "green-lp-100"
      }, null, 8, ["loading", "columns", "data", "isEmpty", "onClick", "isDetailAble", "pagination", "onRequest", "onTryAgain"])
    ])
  ]))
}