
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { CourierManagementDetail } from "@/domain/entities/CourierManagement";
import { Options, Vue } from "vue-class-component";
import { CourierManagementController } from "../../../controllers/CourierManagementController";
import {
  ApiRequestCourierManagementList,
  ApiRequestCourierManagementListHistory,
  UpdateStatusCourierApiRequest,
} from "@/data/payload/api/CourierManagementApiRequest";
import { FlagsPermissionCourierManagement } from "@/feature-flags/flags-permission-courier-management";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import ModalHistoryCourier from "./modal-history-courier.vue";

@Options({
  components: { ModalHistoryCourier },
})
export default class CourierManagementApprovalList extends Vue {
  mounted() {
    FlagsPermissionCourierManagement.permission_courier_management_approval.isEnabled() ? this.fetchList() : '';
  }
  get title(): string {
    return (this.$route as any)?.meta?.title;
  }
  onResetList(): void {
    this.listData.pagination.page = 1;
    this.fetchList();
  }

  listData: ResponsePayloadV2 = new ResponsePayloadV2();
  listHistoryCourierData: ResponsePayloadV2 = new ResponsePayloadV2();
  async fetchList() {
    this.listData.loading = true;
    try {
      this.listData = await CourierManagementController.getListApproval(
        new ApiRequestCourierManagementList({
          page: this.listData.pagination.page,
          limit: 5,
          perPage: 0,
        })
      );
    } catch (err) {
      this.listData.message = parsingErrorResponse(err).type;
    } finally {
      this.listData.loading = false;
    }
  }
  async getListHistoryCourier() {
    this.listHistoryCourierData.loading = true;
    this.historyCourierModal = true;
    console.log(this.idCourier, "id")
    try {
      this.listHistoryCourierData = await CourierManagementController.getListHistoryCourier(
        new ApiRequestCourierManagementListHistory({
          page: this.listHistoryCourierData.pagination.page,
          perPage: 5,
          courierId: this.idCourier
        })
      );
    } catch (err) {
      this.listHistoryCourierData.message = parsingErrorResponse(err).type;
    } finally {
      this.listHistoryCourierData.loading = false;
    }
  }
  get isEmptyList(): boolean {
    return !this.listData.data.length;
  }

  get columns(): any[] {
    return [
      {
        name: "No",
        styleHead: "w-1/20 text-left whitespace-no-wrap",
        render: (_: CourierManagementDetail, index: number) =>
          `<span class='text-left text-black-lp-300 flex justify-center'>${(this
            .listData.pagination.page -
            1) *
            this.listData.pagination.limit +
            index +
            1}</span>`,
      },
      {
        name: "Kurir ID",
        styleHead: "w-2/20 text-left whitespace-no-wrap",
        render: (item: CourierManagementDetail) =>
          `<span class='text-left text-black-lp-300 flex justify-center'>${item.courierId}</span>`,
      },
      {
        name: "Nama Kurir",
        styleHead: "w-3/20 text-left whitespace-no-wrap",
        render: (item: CourierManagementDetail) =>
          `<span class='text-left text-black-lp-300 flex justify-center'>${item.fullname}</span>`,
      },
      {
        name: "No Handphone",
        styleHead: "w-3/20 text-left whitespace-no-wrap",
        render: (item: CourierManagementDetail) =>
          `<span class='text-left text-black-lp-300 flex justify-center'>${item.phoneNumber}</span>`,
      },
      {
        name: "Tipe Kurir",
        styleHead: "w-2/20 text-left whitespace-no-wrap",
        render: (item: CourierManagementDetail) =>
          `<span class='text-left text-black-lp-300 flex justify-center'>${item.courierType}</span>`,
      },
      {
        name: "Plat Nomor",
        styleHead: "w-3/20 text-left whitespace-no-wrap",
        render: (item: CourierManagementDetail) =>
          `<span class='text-black-lp-300 flex justify-center'>${item.vehiclePlate ||
            "-"}</span>`,
      },
      {
        name: `Nama Konsol/POS`,
        styleHead: "w-1/5 text-left whitespace-no-wrap",
        render: (item: CourierManagementDetail) =>
          `<span class='text-black-lp-300 flex justify-center'>${item.partner}</span>`,
      },
      {
        name: "Tipe Permintaan",
        styleHead: "w-3/20 text-left whitespace-no-wrap",
        render: (item: CourierManagementDetail) =>
          `<span class='text-black-lp-300 flex justify-center'>${item.announcementCourierType ||
            "-"}</span>`,
      },
      {
        name: "Atur",
        key: "actionable_column",
        styleHead: "w-40 text-left",
        showButton: () => {
          return true;
        },
      },
    ];
  }

  get isDetailAble() {
    return FlagsPermissionCourierManagement.permission_courier_management_detail_view.isEnabled();
  }

  isOpenModalApproval = false;
  isOpenModalRejected = false;
  isOpenModalSuccess = false;
  historyCourierModal = false;
  idCourier = 0;
  idListCourier = 0;
  typeCourier = "";
  actionType = "";
  async onSubmit(action: string) {
    MainAppController.showLoading();
    this.actionType = action;
    try {
      await CourierManagementController.approveRejectCourier(
        new UpdateStatusCourierApiRequest({
          id: this.idListCourier,
          type: this.typeCourier,
          action: action,
        })
      );
      this.isOpenModalSuccess = true;
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(
          error,
          action === "REJECT"
            ? "Gagal Menolak Kurir"
            : "Gagal Menyetujui Kurir",
          this.onSubmit
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }
  async onSubmitApprove() {
    this.isOpenModalApproval = false;
    await this.onSubmit("APPROVE");
  }

  async onSubmitReject() {
    this.isOpenModalRejected = false;
    await this.onSubmit("REJECT");
  }
  onConfirmSuccess() {
    this.isOpenModalSuccess = false;
    this.fetchList();
  }
  onClose(status: string) {
    switch (status) {
      case "rejected":
        this.isOpenModalRejected = false;
        break;
      case "approval":
        this.isOpenModalApproval = false;
        break;
    }
  }
  onCloseModalHistory() {
    this.historyCourierModal = false;
  }
  actionGroupOptions(item: any) {
    this.idCourier = item.courierId;
    this.idListCourier = item.id;
    this.typeCourier = item.announcementCourierType;
    return [
      {
        label: "Approve",
        clickFunction: () => {
          this.isOpenModalApproval = true;
        },
      },
      {
        label: "Reject",
        clickFunction: () => {
          this.isOpenModalRejected = true;
        },
      },
      {
        label: "History",
        clickFunction: () => {
          this.getListHistoryCourier()
        },
      },
    ];
  }
}
