import { container } from "tsyringe";
import { VuexModule, Module, Action, getModule } from "vuex-module-decorators";
import store from "@/store";
import { CourierManagementPresenter } from "../presenters/CourierManagementPresenter";
import {
  ApiRequestCourierManagementCreate,
  ApiRequestCourierManagementList,
  ApiRequestCourierManagementListHistory,
  UpdateStatusCourierApiRequest,
  ApiRequestCourierVehicleList,
} from "@/data/payload/api/CourierManagementApiRequest";

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "courier-management"
})
class CourierManagementStore extends VuexModule {
  @Action
  public getList(params: ApiRequestCourierManagementList) {
    const presenter = container.resolve(CourierManagementPresenter);
    return presenter.getList(params);
  }

  @Action
  public getListApproval(params: ApiRequestCourierManagementList) {
    const presenter = container.resolve(CourierManagementPresenter);
    return presenter.getListApproval(params);
  }

  @Action
  public getListHistoryCourier(params: ApiRequestCourierManagementListHistory) {
    const presenter = container.resolve(CourierManagementPresenter);
    return presenter.getListHistoryCourier(params);
  }

  @Action
  public downloadFile(params: ApiRequestCourierManagementList) {
    const presenter = container.resolve(CourierManagementPresenter);
    return presenter.downloadFile(params);
  }

  @Action
  public getDetail(id: number) {
    const presenter = container.resolve(CourierManagementPresenter);
    return presenter.getDetail(id);
  }
  @Action
  public createCourier(payload: ApiRequestCourierManagementCreate) {
    const presenter = container.resolve(CourierManagementPresenter);
    return presenter.createCourier(payload);
  }
  @Action
  public editCourier(payload: ApiRequestCourierManagementCreate) {
    const presenter = container.resolve(CourierManagementPresenter);
    return presenter.editCourier(payload);
  }
  @Action
  public approveRejectCourier(payload: UpdateStatusCourierApiRequest) {
    const presenter = container.resolve(CourierManagementPresenter);
    return presenter.approveRejectCourier(payload);
  }
  @Action
  public getListVehicle(params: ApiRequestCourierVehicleList) {
    const presenter = container.resolve(CourierManagementPresenter);
    return presenter.getListVehicle(params);
  }
}

export const CourierManagementController = getModule(CourierManagementStore);
