
import { Options, Vue } from "vue-class-component";
import { FlagsPermissionCourierManagement } from "@/feature-flags/flags-permission-courier-management";
@Options({
  components: {},
})
export default class CourierManagement extends Vue {
  get currentRoute() {
    return this.$route.name;
  }
  // Tabs
  get tabs() {
    const tab = [
      {
        name: "courier-list",
        title: "Management Kurir",
        permission: FlagsPermissionCourierManagement.permission_courier_management_enable,
      },
      {
        name: "courier-list-approval",
        title: "Management Kurir Approval",
        permission: FlagsPermissionCourierManagement.permission_courier_management_approval,
      },
    ];
    return tab;
  }
  get availableTabs() {
    return this.tabs.filter((tab) => tab.permission.isEnabled());
  }

  changeTabs(value: string) {
    this.$router.push({
      name: value,
    });
  }

  beforeMount() {
    this.handleTab();
  }

  handleTab() {
    if(this.availableTabs.length) this.changeTabs(this.availableTabs[0].name);
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }
}
